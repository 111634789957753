@import "./_sass-essentials/essentials.scss";

.rm-mobile-toggle {
  .responsive-menu-toggle-icon {
    width: $toggle-width + ($toggle-v-padding * 2) + ($mt-btn-bw * 2);
    height: $toggle-height + $toggle-h-padding + $toggle-h-padding;
    border: $mt-btn-bw $mt-btn-bs $c-mt-btn-border;
    border-radius: $mt-btn-br;
    padding: $toggle-v-padding $toggle-h-padding;
    background-color: $c-mt-btn-bg;
    position: relative;

    &:before,
    &:after,
    span.icon {
      background-color: $c-mt-bar-bg;
      border-radius: $toggle-bar-radius;
      left: $toggle-v-padding;
      width: $toggle-width;
      height: $toggle-bar-height;
    }

    &:before {
      top: 0 + $toggle-h-padding;
    }

    span.icon {
      top: $toggle-bar2-pos + $toggle-h-padding;
    }

    &:after {
      top: $toggle-bar3-pos + $toggle-h-padding;
    }

    span.label {
      display: none;
    }

    &:hover {
      cursor: pointer;
      background-color: $c-mt-btn-hov-bg;
    }
  }
}

.mm-menu_opened {
  .rm-mobile-toggle {
    .responsive-menu-toggle-icon {
      &:before,
      &:after,
      span.icon {
        background-color: $c-mt-bar-hov-bg;
      }

      &:before {
        transform: translate3d(0, $toggle-bar1-3dpos, 0) rotate(45deg);
      }

      span.icon {
        opacity: 0;
      }

      &:after {
        transform: translate3d(0, $toggle-bar3-3dpos, 0) rotate(-45deg);
      }
    }
  }
}
