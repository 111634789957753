.rm-mobile-toggle .responsive-menu-toggle-icon {
  width: 44px;
  height: 40px;
  border: 2px solid #29363E;
  border-radius: 0;
  padding: 10px 10px;
  background-color: #FFF;
  position: relative;
}

.rm-mobile-toggle .responsive-menu-toggle-icon:before, .rm-mobile-toggle .responsive-menu-toggle-icon:after,
.rm-mobile-toggle .responsive-menu-toggle-icon span.icon {
  background-color: #29363E;
  border-radius: 0;
  left: 10px;
  width: 20px;
  height: 3px;
}

.rm-mobile-toggle .responsive-menu-toggle-icon:before {
  top: 10px;
}

.rm-mobile-toggle .responsive-menu-toggle-icon span.icon {
  top: 17px;
}

.rm-mobile-toggle .responsive-menu-toggle-icon:after {
  top: 24px;
}

.rm-mobile-toggle .responsive-menu-toggle-icon span.label {
  display: none;
}

.rm-mobile-toggle .responsive-menu-toggle-icon:hover {
  cursor: pointer;
  background-color: #FFD400;
}

.mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon:before, .mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon:after,
.mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon span.icon {
  background-color: #29363E;
}

.mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon:before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}

.mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon span.icon {
  opacity: 0;
}

.mm-menu_opened .rm-mobile-toggle .responsive-menu-toggle-icon:after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}
